(function () {
  $('.team-popup__close').click(function () {
    $('.team-popup').removeClass('open')
  })

  $('.person-card').click(function () {
    let $this = $(this)
    let image = $this.find('.person-card__image img').attr('src');
    let name = $this.find('.person-card__name').html();
    let info = $this.find('.person-card__info').html();
    let text = $this.find('.person-card__text').html();
    let fbLink = $this.find('.person-card__fb-link').attr('href')
    let inLink = $this.find('.person-card__in-link').attr('href')
    let lnLink = $this.find('.person-card__ln-link').attr('href')
    let twLink = $this.find('.person-card__tw-link').attr('href')

    let $teamPopup = $('.team-popup');
    $teamPopup.find('.team-popup__image img').attr('src', image);
    $teamPopup.find('.team-popup__title').html(name);
    $teamPopup.find('.team-popup__info').html(info);
    $teamPopup.find('.team-popup__text').html(text);
    $teamPopup.find('.team-popup__fb-link').attr('href', fbLink);
    $teamPopup.find('.team-popup__ln-link').attr('href', lnLink);
    $teamPopup.find('.team-popup__in-link').attr('href', inLink);
    $teamPopup.find('.team-popup__tw-link').attr('href', twLink);
    $teamPopup.addClass('open');
  })
})()