(function () {
  if ($('.events-page').length) {
    $('.event-item').eq(0).find('.event-item__expert').addClass('active');
    if ($(window).width() > 992) {
      let eventHeight = $('.single-event').outerHeight();
      let filtersHeight = $('.filters_events').outerHeight()
      $('.events-list').css({maxHeight: eventHeight - filtersHeight})
    }

    $('.event-item__expert').on('click', function () {
      let $eventItem = $(this).parent()
      $eventItem.toggleClass('active').siblings().removeClass('active');
      if ($(window).width() > 992) {
        $('.single-event').html($eventItem.find('.event').clone())
        let eventHeight = $('.single-event').outerHeight();
        let filtersHeight = $('.filters_events').outerHeight()
        $('.events-list').css({maxHeight: eventHeight - filtersHeight})
      }
    })
  }
})()