(function() {

  $('li.menu-item-has-children').append('<div class="sub-menu-opener"></div>');

  $('.page-header__menu-opener').on('click', function(e) {
    e.preventDefault();
    $('.page-header').toggleClass('menu-opened');
    $('body').toggleClass('overlay');
    if ($('.page-header').hasClass('menu-opened')) {
      $('.main-nav__donate').appendTo('.main-nav__nav')
    } else {
      $('.main-nav__donate').appendTo('.main-nav')
    }
  });

  $(document).click(function (e) {
    if ($('.page-header').hasClass('menu-opened')) {
      if ((!$(e.target).closest('.main-nav__nav').length) && (!$(e.target).closest('.page-header__menu-opener').length)) {
        $('.page-header').removeClass('menu-opened');
        $('body').removeClass('overlay');
        $('.main-nav__donate').appendTo('.main-nav')
      }
    }
  });

  $('.main-nav__search-opener').on('click', function (e) {
    e.preventDefault();
    $('.main-nav__search').addClass('visible');
    if ($(window).width() < 992) {
      $('body').addClass('overlay');
    }
  })

  $('.form_search__close').on('click', function () {
    $('.main-nav__search').removeClass('visible');
    if ($(window).width() < 992) {
      $('body').removeClass('overlay');
    }
  })

  $('.sub-menu-opener').on('click', function () {
    $(this).toggleClass('opened');
    $(this).prev('.sub-menu').toggleClass('opened')
  })
})();
